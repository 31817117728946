import React from 'react'
import GalleryImg from '../galleryimg'
import EvoGallery from '../evoGallery'
import Socials from '../socials'
function Evo()  {
  return (
    <div>
      <GalleryImg/>
      <EvoGallery/>
      <Socials/>
    </div>
  )
}

export default Evo