import React from 'react'
import GalleryImg from '../galleryimg'
import SailGallery from '../SailGallery'
//fuck you code
import Socials from '../socials'
function SailSky()  {
  return (
    <div>
      <GalleryImg/>
      <SailGallery/>
      <Socials/>
    </div>
  )
}

export default SailSky