import React from 'react'
import Noshopd from '../noshopdesktop'

function Shop() {
  return (
    <div>
      <Noshopd/>
    </div>
  )
}

export default Shop