import React from 'react'
import GalleryImg from '../galleryimg'
import ZGallery from '../zGallery'
import Socials from '../socials'
function threeFith()  {
  return (
    <div>
      <GalleryImg/>
      <ZGallery/>
      <Socials/>
    </div>
  )
}

export default threeFith