import React from 'react'
import GalleryImg from '../galleryimg'
import Chaser from '../JonoChaser'
import Socials from '../socials'
function JonoChaser()  {
  return (
    <div>
      <GalleryImg/>
      <Chaser/>
      <Socials/>
    </div>
  )
}

export default JonoChaser