import React from 'react'
import GalleryImg from '../galleryimg'
import Sx240 from '../240sx'
import Socials from '../socials'
function Max240SX()  {
  return (
    <div>
      <GalleryImg/>
      <Sx240/>
      <Socials/>
    </div>
  )
}

export default Max240SX