import React from 'react'
import GalleryImg from '../galleryimg'
import CyiGallery from '../CyiGallery'
import Socials from '../socials'
function CyiGtr()  {
  return (
    <div>
      <GalleryImg/>
      <CyiGallery/>
      <Socials/>
    </div>
  )
}

export default CyiGtr