import React from 'react'
import GalleryImg from '../galleryimg'
import WRXSTIGallery from '../wrxsti'
import Socials from '../socials'
function WRXSTI()  {
  return (
    <div>
      <GalleryImg/>
      <WRXSTIGallery/>
      <Socials/>
    </div>
  )
}

export default WRXSTI