import React from 'react'
import MoiGallery from '../moiGallery'
import GalleryImg from '../galleryimg'

import Socials from '../socials'
function MoiR34()  {
  return (
    <div>
      <GalleryImg/>
      <MoiGallery/>
      <Socials/>
    </div>
  )
}

export default MoiR34