import React from 'react'
import GalleryImg from '../galleryimg'
import GT242Gallery from '../gt242Gallery'
import Socials from '../socials'
function GT242()  {
  return (
    <div>
      <GalleryImg/>
      <GT242Gallery/>
      <Socials/>
    </div>
  )
}

export default GT242