import React from 'react'
import GalleryImg from '../galleryimg'
import ZachGallery from '../zachGallery'
import Socials from '../socials'
function Zachmx5()  {
  return (
    <div>
      <GalleryImg/>
      <ZachGallery/>
      <Socials/>
    </div>
  )
}

export default Zachmx5