import React from 'react'
import GalleryImg from '../galleryimg'
import WESGallery from '../wes13'
import Socials from '../socials'
function WES()  {
  return (
    <div>
      <GalleryImg/>
      <WESGallery/>
      <Socials/>
    </div>
  )
}

export default WES