import React from 'react'
import GalleryImg from '../galleryimg'
import E36Gallery from '../e36'
import Socials from '../socials'
function E36()  {
  return (
    <div>
      <GalleryImg/>
      <E36Gallery/>
      <Socials/>
    </div>
  )
}

export default E36