import React from 'react'
import GalleryImg from '../galleryimg'
import Skyline from '../R33'
import Socials from '../socials'
function DanielR33()  {
  return (
    <div>
      <GalleryImg/>
      <Skyline/>
      <Socials/>
    </div>
  )
}

export default DanielR33