import React from 'react'
import GalleryImg from '../galleryimg'
import LoganGallery from '../loganGallery'
import Socials from '../socials'
function Loganmx5()  {
  return (
    <div>
      <GalleryImg/>
      <LoganGallery/>
      <Socials/>
    </div>
  )
}

export default Loganmx5