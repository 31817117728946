import React from 'react'
import RollaWagon from '../RollaWagon'
import GalleryImg from '../galleryimg'

import Socials from '../socials'
function RollaWagon2()  {
  return (
    <div>
      <GalleryImg/>
      <RollaWagon/>
      <Socials/>
    </div>
  )
}

export default RollaWagon2