import React from 'react'
import GalleryImg from '../galleryimg'
import EndgameGallery from '../endgameGallery'
import Socials from '../socials'
function ENDGAME()  {
  return (
    <div>
      <GalleryImg/>
      <EndgameGallery/>
      <Socials/>
    </div>
  )
}

export default ENDGAME