import React from 'react'
import GalleryImg from '../galleryimg'
import JdM2Gallery from '../jdm2Gallery'
import Socials from '../socials'
function Jdm2()  {
  return (
    <div>
      <GalleryImg/>
      <JdM2Gallery/>
      <Socials/>
    </div>
  )
}

export default Jdm2