import React from 'react'
import GalleryImg from '../galleryimg'
import GC8Gallery from '../gc8Gallery'
import Socials from '../socials'
function GC8()  {
  return (
    <div>
      <GalleryImg/>
      <GC8Gallery/>
      <Socials/>
    </div>
  )
}

export default GC8