import React from 'react'
import GalleryImg from '../galleryimg'
import XR6Gallery from '../xr6'
import Socials from '../socials'
function XR6()  {
  return (
    <div>
      <GalleryImg/>
      <XR6Gallery/>
      <Socials/>
    </div>
  )
}

export default XR6