import React from 'react'
import GalleryImg from '../galleryimg'
import GalantGallery from '../galantGallery'
import Socials from '../socials'
function Galant()  {
  return (
    <div>
      <GalleryImg/>
      <GalantGallery/>
      <Socials/>
    </div>
  )
}

export default Galant