import React from 'react'
import GalleryImg from '../galleryimg'
import IconRx7Gallery from '../IconRx7Gallery'
import Socials from '../socials'
function IconRx7()  {
  return (
    <div>
      <GalleryImg/>
      <IconRx7Gallery/>
      <Socials/>
    </div>
  )
}

export default IconRx7