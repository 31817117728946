import React from 'react'
import GalleryImg from '../galleryimg'
import S14Gallery from '../s14'
import Socials from '../socials'
function S14()  {
  return (
    <div>
      <GalleryImg/>
      <S14Gallery/>
      <Socials/>
    </div>
  )
}

export default S14