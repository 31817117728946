import React from 'react'
import GalleryImg from '../galleryimg'
import Jess180sxGallery from '../jess180sx'
import Socials from '../socials'
function Jess180sx()  {
  return (
    <div>
      <GalleryImg/>
      <Jess180sxGallery/>
      <Socials/>
    </div>
  )
}

export default Jess180sx